import { lazy, memo, React, useState, useEffect } from 'react';
//import AppContext from "../../entryScreen/appcontext";
import Rapidx_Bannerscreen from './Rapidx_banner';
import { ReactComponent as Rapidx_image1 } from './Images/Group_img1.svg';
import { ReactComponent as Rapidx_image2 } from './Images/Group_img2.svg';
import { ReactComponent as Rapidx_image3 } from './Images/Group_img3.svg';
import { ReactComponent as Rapidx_image4 } from './Images/Group_img4.svg';
import { ReactComponent as Rapidx_image5 } from './Images/Group_img5.svg';

import Explore_image1 from './Images/Explore_image1.png';
import Explore_image2 from './Images/Explore_image2.png';
import Explore_image3 from './Images/Group_img6.png';
import Explore_image4 from './Images/Explore_image4.png';
import Setting_icon from './Images/settings_icon.svg';
import LineImg from './Images/slider_rapidx_line1.png';
import CarouselImg from './Images/rapidx_carousel_image1.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 8000,
  pauseOnHover: true
};

function Rapidx_landingpage() {

  return (
    <>
      <div className="rapidx_landingscreen">
      <nav class="navbar navbar-expand-lg navbar-light bg-transparent fixed-top navbar-light bg-light"> 
      <div class="container"> <span href="" class="navbar-brand"> 
      <img src="https://zealous-wave-0a10ee400.1.azurestaticapps.net/img/hexaware_color_logo.svg" alt=""/> 
      <span class="mb-0 font_30 border-left-1 ps-4 ms-4"><b>RapidX</b> </span>
      </span> <button aria-controls="basic-navbar-nav" type="button" aria-label="Toggle navigation" class="navbar-toggler collapsed">
         <span class="navbar-toggler-icon"></span> </button> <div class="navbar-collapse collapse" id="navbarScroll"> 
         <div class="my-2 my-lg-0 w-100 border-left-1 navbar-nav">
           <div class="d-flex w-100 align-items-center">
             <div class="me-auto"> </div> </div> </div> <form class="d-flex align-items-center"></form> </div> </div></nav>
        <section className='ptop90 pb-lg-5'>
        <Rapidx_Bannerscreen />
      </section>
      </div>
      
      <section className="pb_80 pt-5 bg_grey_f8f8f8">
        <div className="container position-relative">
          <div class="linee_bg"></div>
          <div class="row align-items-center pt-4">
            <div className='col-lg-5'>
            <p className='font_36 mb-1 font_bold'> <span className='font_36 color_blu mb-1 font_bold me-2'>Boosting Developer Productivity</span> Across Lifecycle </p>
              {/* <h2 className='font_36 mb-0 font_regular text-danger text-decoration-line-through'>Software</h2>
              <h2 className='font_36 mb-0 font_regular' >Organization</h2> */}
              <p className='font_16 font_light pt-2'>RapidX enables Engineering teams to accomplish more with less by being a trusted ally that automates production grade development of bespoken applications, digital native experiences and modern architecture such as MACH. Faster software development and release cycles enable organizations to reduce time to market of new products and features, respond more effectively to customer needs, and gain a competitive edge.</p>

              {/* Our AI takes your delivery pipelines to the next level. You can automate canary verifications, prioritize what tests to run, determine the impact of changes, automate cloud costs, and much more. */}

            </div>
            <div className='col-lg-2'></div>
            <div className='col-lg-5'>
              {/* <img className='img-fluid ml_13minus' src={Rapidx_image1}></img> */}
              <Rapidx_image1 class="img-fluid"/>
            </div>
          </div>
          <div class="row pt_130 align-items-center">

            <div className='col-lg-5 mobile_pt_50 order-1 order-lg-0 text-end'>
              <Rapidx_image2 class="img-fluid"/>
            </div>
            <div className='col-lg-2'></div>
            <div className='col-lg-5 order-0 order-lg-1'>
            <p className='font_36 mb-1 font_bold'> <span className='font_36 mb-1 font_bold color_blu'>Achieving Quality with Velocity in </span> Software Development</p>
              <p className='font_16 font_light pt-2'>RapidX ensures engineering teams create software development assets with the highest engineering quality and enterprise-grade compliance through its concept of Micro-reference architecture (MRA) building blocks. MRAs provide guardrails that facilitate higher first-time rights and greater organization compliance, leading to a more efficient and productive team that delivers quality outcomes and a seamless digital native experience.</p>
            </div>
          </div>
          <div class="row pt_150 align-items-center">
            <div className='col-lg-6'>
            <p className='font_36 mb-1 font_bold'>
               <span class="color_blu">Standardizing Engineering Excellence for</span> Consistent Results </p>
              {/* <h2 className='font_36 mb-0 font_regular text-danger text-decoration-line-through'>People</h2>
              <h2 className='font_36 mb-0 font_regular'>Knowledge</h2> */}
              <p className='pt-2 font_16 font_light'>RapidX standardises engineering excellence across the teams till the last developer. This enables consistency of engineering mastery across different fusion teams, lines of business and cross-vendors – eventually  creating the foundations to a sustainable IT that is increasingly insulated from skills & talent risks.</p>
            </div>
            <div className='col-lg-2'></div>
            <div className='col-lg-4 mobile_pt_50'>
              <Rapidx_image3 class="img-fluid mle-7"/>
            </div>

          </div>
          <div class="row pt_170 align-items-center">

            <div className='col-lg-5 mobile_pt_50 order-lg-0 order-1'>
              <Rapidx_image4 className='img-fluid ms-0 ps-0 ms-lg-4 ps-lg-2'/>
            </div>
            <div className='col-lg-2'></div>
            <div className='col-lg-5 order-0 order-lg-1'>
            <p className='font_36 mb-1  font_bold'><span class="color_blu">Codifying Wisdom into</span> Engineering Blocks </p>
              <p className='font_16 font_light pt-2'>RapidX enables next-gen pattern-driven development by codifying engineering mastery, industry standards, domain best practices, and unique NFRs into curated engineering blocks. These blocks generate high-quality coded assets, while new patterns encapsulate the latest tech innovations into reusable blocks for custom software development.</p>
            </div>
          </div>
          <div class="row pt_120 align-items-center">
            <div className='col-lg-6'>
            <p className='font_36 mb-1 font_light'> <span class="color_blu">Streamlining Software Supply Chain with</span> Centralized Administration </p>
              <p className='font_16 font_light pt-2 mb-0'>RapidX offers centralized administration of the end-to-end software supply chain, enabling the enforcement of project standards with new tools.  This streamlines the software supply chain and ensures consistency in project delivery, leading to faster releases and improved business outcomes.</p>
            </div>
            <div className='col-lg-2'></div>
            <div className='col-lg-4 mobile_pt_50'>
              <Rapidx_image5 className='img-fluid ms-0 ps-0 ps-lg-2'/>
            </div>
          </div>
        </div>
      </section>
      <section class="py_60 bg_blueee">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 m-auto">
              <div class="Slider_rapidx_div">
                <Slider  {...settings}>
                  <div class="Slider_rapidx_innerdiv text-center">

                    <div class="d-flex flex-column">
                      <p className='font_26 font_regular'>Engineering tomorrow is a self-learning SW engineering platform that codifies the engineering knowledge of legendary architects and developers every day and converts it into engineering building blocks. This new business model allows the average developer or gig to focus solely on writing business logic from day zero while working closely with the business and AI in a true fusion-agile manner. This ensures that all new releases are fast, consistent, with the highest engineering trust, MACH compliant, and are future ready.</p>
                    </div>
                  </div>
                  <div class="Slider_rapidx_innerdiv  text-center">

                    <div class="d-flex flex-column">
                      <p className='font_26 font_regular'>Engineering tomorrow is a self-learning SW engineering platform that codifies the engineering knowledge of legendary architects and developers every day and converts it into engineering building blocks. This new business model allows the average developer or gig to focus solely on writing business logic from day zero while working closely with the business and AI in a true fusion-agile manner. This ensures that all new releases are fast, consistent, with the highest engineering trust, MACH compliant, and are future ready.</p>
                    </div>
                  </div>
                  <div class="Slider_rapidx_innerdiv text-center">

                    <div class="d-flex flex-column">
                      <p className='font_26 font_regular'>Engineering tomorrow is a self-learning SW engineering platform that codifies the engineering knowledge of legendary architects and developers every day and converts it into engineering building blocks. This new business model allows the average developer or gig to focus solely on writing business logic from day zero while working closely with the business and AI in a true fusion-agile manner. This ensures that all new releases are fast, consistent, with the highest engineering trust, MACH compliant, and are future ready.</p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py_60 bg_grey_f8f8f8">
        <div class="container">
          <div class="row mb-4">
            <div class="col-12 text-center">
              <h4 className='font_42 font_medium'>Explore</h4>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-5 pe-lg-5">
              <div class="d-flex align-items-center">
                <div className='iconn_bg_blue'><img src={Setting_icon}></img></div>
                <div className='font_24 font_medium ms-3'>App Designer</div>
              </div>
              <div class="d-flex flex-column mt-4">
                <p className='font_16 font_light'>App designer is created with the developer in mind. It will help onboard developers quickly with the software required for application development. It will help templatize clean architecture patterns into MRA (Micro reference architecture) for enterprise-grade micro apps, API, integration, and backend that delivers digital business use cases. It then allows developers to choose from a catalog of MRA’s which are coded in various tech stacks (React, Node.js, .NET, Java, etc.) with best engineering practices that follow solid principles and clean architecture patterns, thereby accelerating the initial foundation code development. Further, it helps generate functional code (CRUD) based operations code with a fully functional devops pipeline for build and release.</p>
              </div>
              <div class="d-flex">
                <button class="btn Btn-Custom-Primary borderradius25" hidden>Try it Out</button>
                <button class="btn Btn-Custom-Secondary borderradius25" hidden>Learn More</button>
              </div>
            </div>
            <div class="col-lg-7 mobile_pt_50">
              <img className='img-fluid' src={Explore_image1}></img>
            </div>
          </div>
        </div>
      </section>
      <section className="py_60 bg_orangelite">
        <div class="container">
          <div class="row align-items-center">

            <div class="col-lg-7 mobile_pt_50 order-1 order-lg-0">
              <img className='img-fluid' src={Explore_image2}></img>
            </div>
            <div class="col-lg-5 ps-lg-5 order-0 order-lg-1">
              <div class="d-flex align-items-center">
                <div className='iconn_bg_pink'><img src={Setting_icon}></img></div>
                <div className='font_24 font_medium ms-3'>Autonomous Testing & <br />Self-service CICD</div>
              </div>
              <div class="d-flex flex-column mt-4">
                <p className='font_16 font_light'>ATOP (Autonomous Test Orchestration Platform) is a unified platform with a plug-and-play architecture that can become a one-stop solution for all testing (functional and non-functional) needs and to implement autonomous testing use cases.</p>
              </div>
              <div class="d-flex">
                <button class="btn Btn-Custom-Primary borderradius25" hidden>Try it Out</button>
                <button class="btn Btn-Custom-Secondary borderradius25" hidden>Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py_60 bg_grey_f8f8f8">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 pe-lg-5">
              <div class="d-flex align-items-center">
                <div className='iconn_bg_blue'><img src={Setting_icon}></img></div>
                <div className='font_24 font_meduim ms-3'>Omniview</div>
              </div>
              <div class="d-flex flex-column mt-4">
                <p className='font_16 font_light'>Omniview works on the pillars of Empowerment, Accountability, Transparency, and Continuous Improvement- which helps transform organizational culture into empowered teams focused on business outcomes. It empowers teams by building a culture around transparency that allows us to drive business and product outcomes through accountability. The goal is to bring transparency to 100% of engagements and agile metrics that capture insights on story estimation, task identification, identification of high-risk stories, project health analysis, capacity risks identification, behavioral analysis, and usage prediction.</p>
              </div>
              <div class="d-flex">
                <button class="btn Btn-Custom-Primary borderradius25" hidden>Try it Out</button>
                <button class="btn Btn-Custom-Secondary borderradius25" hidden>Learn More</button>
              </div>
            </div>
            <div class="col-lg-7 mobile_pt_50">
              <img className='img-fluid' src={Explore_image3}></img>
            </div>
          </div>
        </div>
      </section>
      <section className="py_60 bg_bluelite">
        <div class="container">
          <div class="row align-items-center">

            <div class="col-lg-7 mobile_pt_50 order-1 order-lg-0">
              <img className='img-fluid' src={Explore_image4}></img>
            </div>
            <div class="col-lg-5 ps-lg-5 order-0 order-lg-1">
              <div class="d-flex align-items-center">
                <div className='iconn_bg_pink'><img src={Setting_icon}></img></div>
                <div className='font_24 font_medium ms-3'>Devportal</div>
              </div>
              <div class="d-flex flex-column mt-4">
                <p className='font_16 font_light'>Devportal is our “one source of truth” for all our technology offerings across our engagement. Here we list turnkey solutions which can be implemented in no time for similar business environments. We have option to search, field a detailed view, and connect with team.</p>
              </div>
              <div class="d-flex">
                <button class="btn Btn-Custom-Primary borderradius25" hidden>Try it Out</button>
                <button class="btn Btn-Custom-Secondary borderradius25" hidden>Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bg_grey_f8f8f8 py_60'>
        <div class="container">
          <div class="row">
            <div class="col-lg-10 m-auto">
              <div className='p-lg-5 p-4 bg_Blue text-white text-center borderradius25'>
                <div class="bgbackground_linebg">
                  <p class="font_42 font_medium px-lg-5 mx-lg-5">The Next-Gen SW Engineering Platform for Modern Enterprises</p>
                 <p className='font_16 font_light px-lg-5 mx-lg-5'>Accelerating business outcomes through an AI-enabled software engineering platform that delivers consistent engineering trust, high velocity and transparency across the software lifecycle</p>
                  <p className='mt-54'>
                    
                  <button className='btn Btn-Custom-Secondary borderradius25' hidden>Get Started</button>
                    <button className='btn Btn-Custom-Primary borderradius25' hidden>Get a Demo</button>
                  </p>
                  <p className='font_16 font_light mt-4'>Need more info? Contact Sales</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <FooterMain /> */}
      <footer className="bg-footerblack">
        <div class="container">
        <div class="row">
        {/* <div class="col-12">
          <p class="text-white font_14 font_regular mb-0">© 2022 Hexaware Technologies Limited. All rights reserved</p>
        </div> */}
        <div class="col-sm-12 d-sm-flex justify-content-end align-items-center text-center p-0">
        <p class="text-white font_14 font_regular mb-0 text-center" style={{marginRight: "auto"}}>© 2023 Hexaware Technologies Limited. All rights reserved</p>
              <ul class="footer-social-list flex-wrap d-none d-sm-flex justify-content-center privacy-policy">            
                <li class="footer-social-list__item" style={{listStyle: "none",fontSize: "14px"}}><a target="_blank" href="https://hexaware.com/disclaimer/">Disclaimer</a></li>
                <li class="footer-social-list__item" style={{listStyle: "none",fontSize: "14px"}}><a target="_blank" href="https://hexaware.com/privacy-policy/">Privacy Policy</a></li>
                <li class="footer-social-list__item" style={{listStyle: "none",fontSize: "14px"}}><a target="_blank" href="https://hexaware.com/privacy-policy/#cookie-policy">Cookie Policy</a></li>
              </ul>
       </div>
      </div>
        </div>
  </footer>

    </>
  )
}

export default memo(Rapidx_landingpage)