import logo from './logo.svg';
import './App.css';
import Rapidx_landingpage from './Rapidx_landingpage'
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <Rapidx_landingpage/>
  );
}

export default App;
