import { lazy, memo, React,useState, useEffect } from 'react';
//import AppContext from "../../entryScreen/appcontext";
import Rapidx_rightBanner from './Images/Banner1.png';
import Arrow_yellow from './Images/arrow_yellow.svg';
import Arrow_red from './Images/arrow_red.svg';
import Arrow_green from './Images/arrow_green.svg';
import Grey_triangle from './Images/grey_triangle.svg';
import Greyred_triangle from './Images/grey_red_triangle.svg';
import Red_triangle from './Images/rocket_img.svg';
import Green_circle from './Images/green_circle.svg';
import Rapdix_lefttext from './Images/rapidx_leftText1.png';

import Yellow_triangle from './Images/two2.svg';
import Yellow_triangle1 from './Images/three3.svg'; 
import Yellow_triangle2 from './Images/one1.svg'; 
import Yellow_triangle3 from './Images/four4.svg'; 
import Yellow_triangle4 from './Images/five5.svg'; 



function Rapidx_Bannerscreen () {

    return (
        <>
        <div class="container pb-5">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    {/* <h2 className='font_55 mb-3 pe-0 me-0 me-lg-4 pt-5 pe-lg-5'>Hexaware RapidX</h2> */}
                    <h2 className='font_55 font_bold color_blu' style={{marginTop: '0.5rem'}}>Hexaware RapidX</h2>
                    <h3 className='font_30 text-center' style={{marginLeft: "163px"}}>Platform</h3>
                    <p className='font_16 font_regular mb-3 pe-0 me-0 me-lg-4 pt-1 pt-lg-3 pe-lg-5'><b>Build and Compose Enterprise Grade Apps at Startup Speed</b></p>
                    <p className='font_16 font_regular mb-3 pe-0 me-0 me-lg-4 pt-1 pt-lg-3 pe-lg-5'>
                    RapidX is Hexaware’s next-gen software engineering platform that accelerates enterprise app development with highest engineering quality & reliable foundations. It empowers engineering teams to achieve more with less effort, fewer skills, and less time. At the same time, it enables product engineering teams to quickly compose new services and create a modern customer experience using up-to-date application design patterns, resulting in faster product releases
                    </p>
                   
                    <p className='mt-4'>
                        <button className='btn Btn-Custom-Primary borderradius25 pt-2' hidden>Get a Demo</button>
                    </p>
                </div>
                <div class="col-lg-6 mb-4 mt-5 mt-lg-0 position-relative text-center">
                    <h2 className='font_36 font_bold color_blu mt-3 text-center'>“ENGINEERING TOMORROW”</h2>
                    <img class="img-fluid mt-3 " src={Rapidx_rightBanner}/>
                    <div class="green_ellipse">
                        <span class='greenEllipse'></span>
                    </div>
                    <div class="grey_triangle">
                        <img className='img-fluid' src={Grey_triangle}></img>
                    </div>
                    <div class="greyred_triangle">
                        <img className='img-fluid' src={Greyred_triangle}></img>
                    </div>
                    <div class="yellow_rect">
                        <img className='img-fluid' src={Yellow_triangle}></img>
                        <span class="ellipse"></span>
                    </div>
                    <div class="yellow_rect1">
                        <img className='img-fluid' src={Yellow_triangle1}></img>
                    </div>
                    <div class="yellow_rect2">
                        <img className='img-fluid' src={Yellow_triangle2}></img>
                    </div>
                    <div class="yellow_rect3">
                        <img className='img-fluid' src={Yellow_triangle3}></img>
                    </div>
                    <div class="yellow_rect4">
                        <img className='img-fluid' src={Yellow_triangle4}></img>
                    </div>
                </div>
            </div>
        </div>
        
        </>
    )
}

export default memo(Rapidx_Bannerscreen)